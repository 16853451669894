<template>
  <v-row dense>
    <v-col
      cols="12"
      md="12"
    >
      <v-slide-group
        class="px-4"
        show-arrows="always"
      >
        <v-slide-item
          class="ma-1"
          v-for="(item, idx) in videos"
          :key="idx"
        >
          <div
            @mouseover="showBtn = idx"
            @mouseleave="showBtn = null"
          >
            <video
              width="300"
              height="150"
              paused
              controls
            >
              <source
                :src="
                  item.src
                    ? $store.state.imagePath + item.src
                    : videoMedia[idx].src
                "
              />
            </video>
            <transition name="slideIn">
              <v-btn
                v-if="showBtn == idx"
                @click="removeVideo(idx)"
                class="error"
                dark
                fixed
                left
                top
                icon
              >
                <v-icon small>fas fa-trash</v-icon>
              </v-btn>
            </transition>
          </div>
        </v-slide-item>
        <v-slide-item>
          <v-card
            @click="addVideo"
            class="ma-1 elevation-0 d-flex align-center justify-center u-border-dashed"
            height="150"
            width="300"
          >
            <v-icon color="primary">fas fa-plus</v-icon>
          </v-card>
        </v-slide-item>
        <input
          class="d-none"
          @change="onFileChange"
          ref="videoUploader"
          type="file"
          accept="video/*"
        />
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    videos: {
      type: Array,
    },
  },
  data: () => ({
    videoMedia: [],
    showBtn: null,
  }),
  watch: {
    videos: function (oldvalue, newvalue) {
      let videoArr = []
      newvalue.map((video) => {
        let src = URL.createObjectURL(video)
        videoArr.push({ src: src })
      })
      this.videoMedia = videoArr
    },
  },
  methods: {
    removeVideo(idx) {
      let id = this.videos.indexOf(idx)
      this.videos.splice(id, 1)
    },
    addVideo() {
      this.$refs.videoUploader.click()
    },
    onFileChange(e) {
      this.videos.push(e.target.files[0])
    },
  },
}
</script>

<style lang="scss">
.u-border-dashed {
  border: 2px dashed $primary !important;
}
.slideIn-enter,
.slideIn-leave-to {
  transform: translate(40px, -40px);
}

.slideIn-enter-active,
.slideIn-leave-active {
  transition: all 0.2s;
}

.slideIn-enter-to,
.slideIn-leave {
  transform: translate(0, 0);
}
</style>
